import React, { Component } from "react"

import Cereal from "../dataVizPages/cerealPage"
import Animal from "../dataVizPages/animalcrossing"
import animalthumb from "../assets/pictures/animalCrossing/tinderthumb.png"

class DataCell extends Component {
  state = {
    boxClass1: "box__row-cell",
    backImage1: `url(${animalthumb})`,
    boxClass2: "box__row-cell",
    closeClass: "box__close",
  }

  showContent1 = () => {
    this.setState(state => ({
      boxClass1: "box__row-cell active",
      backImage1: "none",
      closeClass: "box__close box-close-active",
    }))
  }

  showContent2 = () => {
    this.setState(state => ({
      boxClass2: "box__row-cell active",
      closeClass: "box__close box-close-active",
    }))
  }

  hideContent = () => {
    this.setState(state => ({
      boxClass1: "box__row-cell",
      backImage1: `url(${animalthumb})`,
      boxClass2: "box__row-cell",
      closeClass: "box__close",
    }))
  }
  render() {
    return (
      <div className="box__row">
        <div className={this.state.boxClass1} style={{backgroundImage: this.state.backImage1, backgroundRepeat: "no-repeat", backgroundSize: 'cover'}} onClick={this.showContent1}>
          <div className="box__row-cell-info">
            <p className="box__row-cell-info-title">Animal Crossing Tinder</p>
          </div>
          <div className="box__row-cell-info--full">
            <Animal />
          </div>
        </div>

        <div className={this.state.boxClass2} onClick={this.showContent2}>
          <div className="box__row-cell-info">
            <p className="box__row-cell-info-title">80 Cereals</p>
          </div>
          <div className="box__row-cell-info--full">
            <Cereal/>
          </div>
        </div>

        <div className={this.state.closeClass} onClick={this.hideContent}></div>
      </div>
    )
  }
}

export default DataCell
