import React from "react"
import { Image, Row, Col } from "react-bootstrap"


function CerealInfo(props) {

    return (
      <>
        <Row>
            <Col sm={"auto"}>
                <Image src={require("../assets/pictures/cereal/"+ props.image).default} width="150vh" height="auto"></Image>
            </Col>
            <Col>
                <p className="title" style={{fontSize:"25px"}}>{props.name}</p>
                <hr></hr>
                <Row>
                    <p className="text" style={{marginBottom: "10px"}}>Manufacturer: {props.manufact}</p>
                </Row>
            </Col>
            <Col>
                <section className="performance-facts" style={{margin: "0px"}}>
                    <header className="performance-facts__header">
                        <h1 className="performance-facts__title">Nutrition Facts</h1>
                        <p style={{lineHeight: "14px"}}>Serving Size {props.cups}</p>
                    </header>
                    <table className="performance-facts__table">
                        <thead>
                            <tr>
                                <th colSpan="3" className="small-info" stlye={{lineHeight: "8px"}}>
                                    Amount Per Serving
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th colSpan="2" style={{lineHeight: "12px"}}>
                                    <b>Calories</b> 
                                    {props.calories}
                                </th>
                            </tr>
                            <tr className="thick-row"></tr>
                            <tr>
                                <th colSpan="2" style={{lineHeight: "12px"}}>
                                    <b>Total Fat</b> 
                                    {props.fat}g
                                </th>
                            </tr>
                            <tr>
                                <th colSpan="2" style={{lineHeight: "12px"}}>
                                    <b>Sodium</b> 
                                    {props.sodium}mg
                                </th>
                            </tr>
                            <tr>
                                <th colSpan="2" style={{lineHeight: "12px"}}>
                                    <b>Total Carbohydrate</b> 
                                    {props.carb}g
                                </th>
                            </tr>
                            <tr>
                                <td className="blank-cell"></td>
                                <th style={{lineHeight: "12px"}}>
                                    Dietary Fiber 
                                    {props.fiber}g
                                </th>
                            </tr>
                            <tr>
                                <td className="blank-cell"></td>
                                <th style={{lineHeight: "12px"}}>
                                    Sugars 
                                    {props.sugar}g
                                </th>
                            </tr>
                            <tr className="thick-end">
                                <th colSpan="2" style={{lineHeight: "12px"}}>
                                    <b>Protein</b>
                                    {props.protein}g
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    <table className="performance-facts__table--grid" stlye={{marginBottom: "0px"}}>
                        <tbody>
                            <tr>
                                <td colSpan="2" style={{lineHeight: "12px"}}>
                                    Vitamins 
                                    {props.vitamin}%
                                </td>
                                <td style={{lineHeight: "12px"}}>
                                    Potassium 
                                    {props.potass}mg
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </Col>
        </Row>
      </>
    )
  }


export default CerealInfo
