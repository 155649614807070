import React from "react"
import {Row, Col} from "react-bootstrap"

const AnimalCard = ({ name, image, phrase, quote, goal, sign, song, album}) => {
    return (
        <>
        <div className="demo__card" id="1-27,Admiral">
            <div className="demo__card__top">
            <div className="scroll">
                <img
                src={require("../assets/pictures/animalCrossing/posters/" + image).default}
                style={{ height: "400px" }}
                alt=""
                />
                <p
                className="demo__card__name"
                style={{ marginBottom: "0px" }}
                >
                {name},{" "}
                <img src={require("../assets/pictures/animalCrossing/horoscope/" + sign + "-2-24.png").default} alt="" />
                </p>
                <div className="profile-margin">
                    <p className="profile-title" style={{ margin: "0px" }}>
                        {name},{" "}
                        <img src={require("../assets/pictures/animalCrossing/horoscope/black-" + sign + "-2-24.png").default} alt=""/>
                    </p>
                    <hr></hr>
                    <p>
                        {phrase}
                        <br/>
                        {quote}
                        <br/>
                        I'm an aspiring {goal}
                    
                    </p>
                    <Row>
                        <Col>
                            
                            <p>
                                <span style={{fontWeight: "bold"}}>My Anthem</span>
                                <br/>
                                {song}
                                <br />
                                <img
                                src={require("../assets/pictures/icons/spotify.png").default}
                                alt=""
                                width="20px"
                                />{" "}
                                K.K. Slider
                            </p>
                        </Col>
                        <Col md="auto">
                            <img
                                src={require("../assets/pictures/animalCrossing/songcovers/" + album).default}
                                alt=""
                                width="60px"
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            </div>
        </div>
      </>
  )
}

export default AnimalCard
