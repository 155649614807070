import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Cell from "../components/dataCell"

const DataPage = () => (
  <Layout>
    <SEO title="Data Visualizations" />
    <div className="section-row">
      <Cell />
    </div>
  </Layout>
)

export default DataPage
