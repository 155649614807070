import React from "react"

import { Image, Row, Col, Card } from "react-bootstrap"

import w3 from "../assets/pictures/icons/w3.png"
import codepen from "../assets/pictures/icons/codepen.png"
import stackoverflow from "../assets/pictures/icons/stackoverflow.png"

import AnimalCard from "../components/animal_card"

const Animal = () => {
  return (
    <>
      <Row>
        <Col>
          <div className="smartphone">
            <div className="content" style={{ width: "auto", height: "auto" }}>
              <div className="demo">
                <div className="demo__content">
                  <div className="demo__card-cont">
                    <AnimalCard 
                      name="Admiral" 
                      image="admiral.png"
                      phrase="aye aye"
                      quote="Only quitters give up"
                      goal="Fisherman"
                      sign="aquarius"
                      song="Steep Hill" 
                      album="steephill.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col style={{ overflowY: "auto", height: "100vh" }}>
          <p className="title">Animal Crossing Tinder</p>
          <p className="text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>

          <p className="title">Instructions</p>
          <p className="text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>

          <p className="title">Data</p>
          <blockquote style={{ backgroundColor: "#eeeeee", padding: "10px" }}>
            <p className="text" style={{ marginBottom: "0px" }}>
              This data is made available under a
              <a href="https://creativecommons.org/publicdomain/zero/1.0/">
                Creative Commons 1.0 Universal Public Domain Dedication
              </a>
              <br />
              <cite>
                Li, Jessica.(2020). Animal Crossing New Horizons NookPlaza
                Catalog[CSV file]. Kaggle. Retrieved from{" "}
                <a href="https://www.kaggle.com/jessicali9530/animal-crossing-new-horizons-nookplaza-dataset">
                  https://www.kaggle.com/jessicali9530/animal-crossing-new-horizons-nookplaza-dataset
                </a>
              </cite>
            </p>
          </blockquote>

          <Row>
            <Col sm={3}>
              <p className="text">
                villagers.csv <br />
                (78.69 KB)
              </p>
              <div className="tags has-addons">
                <span className="tag">Columns</span>
                <span className="tag is-primary">17</span>
              </div>
              <div className="tags has-addons">
                <span className="tag">Rows</span>
                <span className="tag is-primary">391</span>
              </div>
            </Col>
            <Col>
              <table
                className="table is-bordered is-narrow"
                style={{ marginBottom: "50px" }}
              >
                <thead>
                  <tr>
                    <th>Column</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>Villager name</td>
                  </tr>
                  <tr>
                    <td>Species</td>
                    <td>Manufacturer</td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td>Type of cereal. H (Hot) or C (Cold)</td>
                  </tr>
                  <tr>
                    <td>Personality</td>
                    <td>Number of calories per seriving</td>
                  </tr>
                  <tr>
                    <td>Hobby</td>
                    <td>Grams of protein in cereal serving</td>
                  </tr>
                  <tr>
                    <td>Birthday</td>
                    <td>Grams of fat in cereal serving</td>
                  </tr>
                  <tr>
                    <td>Catchphrase</td>
                    <td>Miligrams of sodium in cereal serving</td>
                  </tr>
                  <tr>
                    <td>Favorite Song</td>
                    <td>Grams of fiber in cereal serving</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>

          <p className="title">Acknowledgments & Inspiration</p>
          <Row>
            <Col>
              <Card style={{ margin: "10px", marginRight: "0px" }}>
                <Card.Body>
                  <Row>
                    <Col sm={4}>
                      <Image
                        src={w3}
                        style={{ height: "64px", width: "64px" }}
                      />
                    </Col>
                    <Col>
                      <p className="text" style={{ marginBottom: "0px" }}>
                        <a href="https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_device_smartphone">
                          Smartphone Device
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card style={{ margin: "10px", marginRight: "0px" }}>
                <Card.Body>
                  <Row>
                    <Col sm={4}>
                      <Image
                        src={codepen}
                        style={{ height: "64px", width: "64px" }}
                      />
                    </Col>
                    <Col>
                      <p
                        className="text"
                        style={{ marginBottom: "0px", fontSize: "15px" }}
                      >
                        <a href="https://codepen.io/suez/pen/MaeVBy">
                          Tinder-like Card Swipe Interaction
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card style={{ margin: "10px", marginLeft: "0px" }}>
                <Card.Body>
                  <Row>
                    <Col sm={4}>
                      <Image
                        src={codepen}
                        style={{ height: "64px", width: "64px" }}
                      />
                    </Col>
                    <Col>
                      <p className="text" style={{ marginBottom: "0px" }}>
                        <a href="https://codepen.io/knyttneve/pen/QVqyNg">
                          CSS grid calendar
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card style={{ margin: "10px", marginLeft: "0px" }}>
                <Card.Body>
                  <Row>
                    <Col sm={4}>
                      <Image
                        src={stackoverflow}
                        style={{ height: "64px", width: "64px" }}
                      />
                    </Col>
                    <Col>
                      <p className="text" style={{ marginBottom: "0px" }}>
                        <a href="https://stackoverflow.com/questions/13250325/show-hide-div-on-scroll">
                          Show/Hide Div on Scroll
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <p className="title">References</p>
          <ol style={{ marginBottom: "200px" }}>
            <Row style={{ marginBottom: "75px" }}>
              <Col sm={5}>
                <li className="citation">
                  All 391 villagers' Posters{" "}
                  <sup>
                    <a href="https://animalcrossing.fandom.com/wiki/Villager_list_(New_Horizons)">
                      [1]
                    </a>
                  </sup>
                </li>
                <li className="citation">
                  All villager face icons{" "}
                  <sup>
                    <a href="https://imgur.com/a/3NnyBTM">[1]</a>
                  </sup>
                </li>
                <li className="citation">
                  Black emoji{" "}
                  <sup>
                    <a href="https://hotemoji.com/medium-black-circle-emoji.html">
                      [1]
                    </a>
                  </sup>
                </li>
                <li className="citation">
                  Green emoji{" "}
                  <sup>
                    <a href="https://commons.wikimedia.org/wiki/File:LACMTA_Circle_Green_Line.svg">
                      [1]
                    </a>
                  </sup>
                </li>
                <li className="citation">
                  Red emoji{" "}
                  <sup>
                    <a href="https://emojiterra.com/red-circle/">[1]</a>
                  </sup>
                </li>
                <li className="citation">
                  Pink emoji{" "}
                  <sup>
                    <a href="https://toppng.com/ink-circle-clip-art-at-clker-pink-circle-clipart-PNG-free-PNG-Images_273825?search-result=green-circle-paint-splatter">
                      [1]
                    </a>
                  </sup>
                </li>
                <li className="citation">
                  Rainbow emoji{" "}
                  <sup>
                    <a href="https://emojiterra.com/rainbow/">[1]</a>
                  </sup>
                </li>
                <li className="citation">
                  Yellow emoji{" "}
                  <sup>
                    <a href="https://emojiguide.org/yellow-circle">[1]</a>
                  </sup>
                </li>
                <li className="citation">
                  Blue emoji{" "}
                  <sup>
                    <a href="https://hotemoji.com/blue-circle-emoji.html">
                      [1]
                    </a>
                  </sup>
                </li>
                <li className="citation">
                  Purple emoji{" "}
                  <sup>
                    <a href="https://emojiterra.com/purple-circle/">[1]</a>
                  </sup>
                </li>
                <li className="citation">
                  White emoji{" "}
                  <sup>
                    <a href="https://hotemoji.com/medium-white-circle-emoji.html">
                      [1]
                    </a>
                  </sup>
                </li>
                <li className="citation">
                  Gray emoji{" "}
                  <sup>
                    <a href="https://www.uokpl.rs/rsvi/hmooTbx_rageline-gray-circle/">
                      [1]
                    </a>
                  </sup>
                </li>
                <li className="citation">
                  Brown emoji{" "}
                  <sup>
                    <a href="https://emojiterra.com/brown-circle/">[1]</a>
                  </sup>
                </li>
                <li className="citation">
                  Aqua emoji{" "}
                  <sup>
                    <a href="https://nce.aasa.org/">[1]</a>
                  </sup>
                </li>
                <li className="citation">
                  Target emoji{" "}
                  <sup>
                    <a href="google.com/url?sa=i&url=http%3A%2F%2Ficon-library.com%2Ficon%2Fgoal-icon-png-24.html&psig=AOvVaw1HjrZyGllrcEWP0xBLBykT&ust=1594283695525000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCKDg0umfveoCFQAAAAAdAAAAABAD">
                      [1]
                    </a>
                  </sup>
                </li>
              </Col>
              <Col>
                <li className="citation">
                  Modern art emoji{" "}
                  <sup>
                    <a href="https://www.kisscc0.com/clipart/cubism-graphic-arts-modern-art-abstract-art-bichit-6ze8df/">
                      [1]
                    </a>
                  </sup>
                </li>
                <li className="citation">
                  Basic emoji{" "}
                  <sup>
                    <a href="https://emojiterra.com/neutral-face/">[1]</a>
                  </sup>
                </li>
                <li className="citation">
                  Rock emoji{" "}
                  <sup>
                    <a href="https://www.pngwing.com/en/free-png-yposi">[1]</a>
                  </sup>
                </li>
                <li className="citation">
                  Cute emoji{" "}
                  <sup>
                    <a href="https://emojiterra.com/smiling-face-with-3-hearts/">
                      [1]
                    </a>
                  </sup>
                </li>
                <li className="citation">
                  Flashy emoji{" "}
                  <sup>
                    <a href="https://emojiterra.com/sparkles/">[1]</a>
                  </sup>
                </li>
                <li className="citation">
                  Open book emoji{" "}
                  <sup>
                    <a href="https://emojiterra.com/open-book/">[1]</a>
                  </sup>
                </li>
                <li className="citation">
                  Running emoji{" "}
                  <sup>
                    <a href="https://creazilla.com/nodes/54350-person-running-emoji-clipart">
                      [1]
                    </a>
                  </sup>
                </li>
                <li className="citation">
                  Mirror emoji{" "}
                  <sup>
                    <a href="https://emojiterra.com/mirror/">[1]</a>
                  </sup>
                </li>
                <li className="citation">
                  Sunglasses emoji{" "}
                  <sup>
                    <a href="https://hotemoji.com/sunglasses-emoji.html">[1]</a>
                  </sup>
                </li>
                <li className="citation">
                  Briefcase emoji{" "}
                  <sup>
                    <a href="https://www.emoji.co.uk/view/10642/">[1]</a>
                  </sup>
                </li>
              </Col>
            </Row>
          </ol>
        </Col>
        <Col>
          <svg
           id="dotgraph"
           width="30vh"
           height="100vh"
           backgroundColor="blue"
          ></svg>
        </Col>
      </Row>
    </>
  )
}
export default Animal
