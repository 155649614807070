import React, {Component} from "react"
import * as d3 from 'd3'
import { Image, Row, Col, Card } from "react-bootstrap"

import spoon from "../assets/pictures/cereal/spoon.png"
import jsbin from "../assets/pictures/icons/js-bin.svg"
import codepen from "../assets/pictures/icons/codepen.png"

import data from "../assets/data/cereal.json"

import CerealInfo from "../components/cerealInfo"

class Cereal extends Component{
  constructor(props) {
    super(props);
    this.state = {
      name: "100% Bran",
      image: "100bran.jpg",
      manufact: "Nabsico",
      cups: "0.33",
      calories: "70",
      fat: "1",
      sodium: "130",
      carb: "5",
      fiber: "10",
      sugar: "6",
      protein: "4",
      vitamin: "25",
      potass: "280",
      brand: "Nutritional Value of 77 Cereals",
      total: ""
    }
  }

  changeContent = (name, image, manufact, cups, calories, fat, sodium, carb, fiber, sugar, protein, vitamin, potass) => {
    this.setState({
        name: name,
        image: image,
        manufact: manufact,
        cups: cups,
        calories: calories,
        fat: fat,
        sodium: sodium,
        carb: carb,
        fiber: fiber,
        sugar: sugar,
        protein: protein,
        vitamin: vitamin,
        potass: potass
    })
  }
  
  componentDidMount() {
    const keys = ["American Home Food Products", "General Mills", "Kelloggs", "Nabisco", "Post", "Quaker Oats", "Ralston Purina"];
    const dimensions = ["protein", "fat", "sodium", "fiber", "carbo", "sugars", "potass", "vitamins"]
    const totalCereal = {"American Home Food Products": 1,"General Mills": 22, "Kelloggs": 23, "Nabisco": 6, "Post": 9, "Quaker Oats": 8, "Ralston Purina": 8}
    const titles = ["Protein", "Fat", "Sodium", "Fiber", "Carbohydrates", "Sugars", "Potassium", "Vitamins"]

    const color = d3.scaleOrdinal(d3.schemeCategory10).domain(keys)

    var tooltip = d3.select('body').append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);
    
    const svg = d3.select(this.refs.cerealRef)
    const svg2 = d3.select(this.refs.allpara)
    const svg3 = d3.select(this.refs.legendSvg)
    const svg4 = d3.select("#allcups").append("g").attr("transform", "translate(" + 20 + "," + 0 + ")")
    const svg5 = d3.select("#allcalories").append("g").attr("transform", "translate(" + 20 + "," + 0 + ")")

    const width = 750;
    const height = 280;
    let x = d3.scalePoint()
      .range([0, width])
      .padding(1)
      .domain(dimensions);

    var y = {}
    for (let i in dimensions) {
      let dim = dimensions[i]
      y[dim] = d3.scaleLinear()
        .domain( d3.extent(data, function(d) { return +d[dim]; }) )
        .range([height, 25])
    }

    function path(d) {
      return d3.line()(dimensions.map(function(p) { return [x(p), y[p](d[p])]; }));
    }

    svg2.append("g")
      .attr("class", "background")
      .selectAll("path")
      .data(data)
      .enter().append("path")
      .attr("d", path);

    let foreground = svg2.append("g")
      .attr("class", "foreground")
      .selectAll("path")
      .data(data)
      .enter().append("path").attr("stroke", (d) => {
        switch(d.mfr){
          case "A":
            return color(0);
          case "G":
            return color(1);
          case "K":
            return color(2);
          case "N":
            return color(3);
          case "P":
            return color(4);
          case "Q":
            return color(5);
          case "R":
            return color(6);
          default:
            return color(7);
        }
      })
      .attr("d", path);
  
    svg2.selectAll(".dimension")
      .data(dimensions)
      .enter()
      .append("g")
      .attr("class", "dimension")
      .attr("transform", function(d) { return "translate(" + x(d) + ")"; })
      .attr("class", "axis")
      .each(function(d) { d3.select(this).call(d3.axisLeft().scale(y[d])); })
      .append("text")
      .style("text-anchor", "middle")
      .attr("y", 9)
      .text(function(d,i) {return titles[i];})
      .style("fill", "black")

    var sorted = data.sort(function(a, b){return b.calories - a.calories})
    // var sorted = data.sort(function(a, b){return a.mfr.localeCompare(b.mfr)})
    // console.log(sortMan)
    
    var x_bar = d3.scaleBand()
      .range([ 10, 2400 ])
      .domain(sorted.map(function(d) {  return d.name; }))
      .padding(0.1);

    var y_bar = d3.scaleLinear()
      .domain([0, 160])
      .range([height, 10]);

    svg4.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x_bar).tickFormat((d)=>{return ""}))
      .selectAll(".tick").each(function(d, i){
        d3.select(this)
        .append("svg:image")
        .attr("xlink:href", require("../assets/pictures/cereal/" + sorted[i].boxImage).default)
        .attr("transform", "translate(-12,0)")
        .attr("height", 40)
        .attr("width", 25)
      });

    svg4.append("g")
      .attr("transform", "translate(10,0)")
      .call(d3.axisLeft(y_bar));
    
    svg4.selectAll(".bar")
      .data(sorted)
      .enter().append("rect")
      .attr("x", function(d) { return x_bar(d.name); })
      .attr("y", function(d){return y_bar(d.calories);})
      .attr("width", 27)
      .attr("height", function(d){return height - y_bar(d.calories)})
      .attr("fill", function(d){
        switch(d.mfr){
          case "A":
            return color(0);
          case "G":
            return color(1);
          case "K":
            return color(2);
          case "N":
            return color(3);
          case "P":
            return color(4);
          case "Q":
            return color(5);
          case "R":
            return color(6);
          default:
            return color(7);
        }
      });

    var y_bar2 = d3.scaleLinear()
      .domain([1.5, 0])
      .range([height, 10]);

    svg5.append("g")
      .attr("transform", "translate(10,-5)")
      .call(d3.axisLeft(y_bar2));
    
    sorted.forEach(function(d, i){
      svg5.append("defs")
      .append("pattern")
      .attr("id", "cerealPattern" + i)
      .attr("patternUnits", "userSpaceOnUse")
      .attr('width', 30)
      .attr('height', 22)
      .append('svg:image')
      .attr("xlink:href", require("../assets/pictures/cereal/" + d.cerealImage).default)
      .attr("width", 20)
      .attr("height", 25)
      .attr("x", 0)
      .attr("y", 0);
    })
    
    svg5.append("g")
      .attr("transform", "translate(0,0)")
      .call(d3.axisBottom(x_bar).tickFormat((d)=>{return ""}));

    svg5.selectAll(".bar")
      .data(sorted)
      .enter().append("rect")
      .attr("x", function(d) { return x_bar(d.name); })
      .attr("y", 0)
      .attr("width", 27)
      .attr("height", function(d){return y_bar2(d.cups)})
      .attr("fill", function(d, i) {return "url(#cerealPattern"+ i +")"});

    const nodes = svg.append("g").attr('id', 'nodes');

    let simulation = d3.forceSimulation();
  
    simulation = simulation
      .force("collide", d3.forceCollide(30).iterations(12))
      .force("charge", d3.forceManyBody())
      .velocityDecay(0.75)
      .alphaDecay(0.006)
      .force("center", d3.forceCenter(350, 450))
      .force("y", d3.forceY(0))
      .force("x", d3.forceX(0))
      
    const ticked = () => {
      nodes.selectAll("circle")
        .attr("cx", d => d.x)
        .attr("cy", d => d.y);
    }
  
    simulation
      .nodes(data)
      .on("tick", ticked);

    data.forEach(function(d, i){
      nodes.append("defs")
      .append("pattern")
      .attr("id", "cerealPattern2" + i)
      .attr('width', 25)
      .attr('height', 25)
      .append('svg:image')
      .attr("xlink:href", require("../assets/pictures/cereal/" + d.cerealImage).default)
      .attr("width", 50)
      .attr("height", 50)
      .attr("x", 0)
      .attr("y", 0);
    })

    nodes.selectAll("circle")
      .data(simulation.nodes(), d => d.name)
      .enter().append("circle")
      .attr("r", 25)
      .attr("fill", function(d, i) {return "url(#cerealPattern2"+ i +")"})
      .on("mouseover", function(d, i){
        tooltip.html(`<p class="text" style="font-size: 12px">${i.name}</p>`)
          .style("width", "80px")
          .style("height", "auto")
          .style("left", (d.x ) + 'px')
          .style("top", (d.y ) + 'px')
          .style("opacity", .9)
          .transition()
          .duration(200);
      })
      .on("mouseleave", function(d){
        tooltip.style("opacity", 0);
      })
      .on("click", (d,i) => {
        let manufacture = "";
        switch(i.mfr){
          case "A":
            manufacture = "American Home Food Products";
            break;
          case "G":
            manufacture = "General Mills";
            break;
          case "K":
            manufacture = "Kelloggs";
            break;
          case "N":
            manufacture = "Nabisco";
            break;
          case "P":
            manufacture = "Post";
            break;
          case "Q":
            manufacture = "Quaker Oats";
            break;
          case "R":
            manufacture = "Ralston Purina";
            break;
          default:
            manufacture = "";
        }
        this.changeContent(i.name, i.boxImage, manufacture, i.cups, i.calories, i.fat, i.sodium, i.carbo, i.fiber, i.sugars, i.protein, d.vitamins, i.potass)
        this.setState({brand: manufacture, total: i.name})
        foreground.style("display", e => { return e.name === i.name ? null : "none"})
      });

    const legend = svg3.append("g")
      .attr("class", "legend")
      .attr("transform", "translate(7, 0)")

    const lg = legend.selectAll('g')
      .data(keys)
      .enter()
      .append('g')
      .attr('transform', (d,i) => `translate(${i * 100},${300 - 170})`);
    lg.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 10)
      .attr('height', 10)
      .attr("fill", function(d,i) { return color(i)})
      .on("click", (d,i) => {
        this.setState({brand: i, total: "Total Number of Cereals: " + totalCereal[i]});
        foreground.style("display", e => { return e.mfr === i.substring(0,1) ? null : "none"})
      });
    lg.append('text')
      .style('font-family', 'Georgia')
      .style('font-size', '12px')
      .attr('x', 17.5)
      .attr('y', 10)
      .text(d => d === "American Home Food Products" ? "American Home" : d);
    lg.attr('transform', function(d, i) {
      return `translate(${5},${(i+1) * 25})`;
    });

  }
  render () {
    return (
      <div>
        <Row>
          <Col>
            <svg ref="cerealRef" id="cereal" width="100%" height="800">
              <circle
                id="bowlout"
                cx="350"
                cy="450"
                r="330"
                stroke="none"
                fill="#45b6fe"
              />
              <circle
                id="bowlin"
                cx="350"
                cy="450"
                r="310"
                stroke="none"
                fill="#2a9df4"
              />
              <circle
                id="milkout"
                cx="350"
                cy="450"
                r="270"
                stroke="none"
                fill="white"
              />
              <image
                href={spoon}
                height="500"
                width="450"
                transform="rotate(170, 280, 220)"
              />
              <circle
                id="milkin"
                cx="350"
                cy="450"
                r="260"
                stroke="none"
                fill="white"
              />
            </svg>
          </Col>
          <Col
            style={{ paddingRight: "40px", overflowX: "hidden", overflowY: "auto", height: "100vh" }}
          >
            <p className="title">80 Cereals</p>
            <p className="text" style={{marginBottom: "20px"}}>
              More like 77 Cereals. In this data visualization, the brands and 
              nutrition values of 77 cereals from the 80 Cereal dataset are compared 
              and analyzed. First on the right, the cereal bowl Force Directed Graph 
              represents every cereal entry as a floating cereal grain(?) in milk.
              Below there is a Parallel Coordinates Chart graphing each cereal's 
              nutritional values. And finally further down there is a dynamic 
              description for a selected cereal node from the cereal bowl graph that 
              displays the cereal box and the nutrition facts.  
            </p>
            <Row>
              <Col style={{padding: "30px"}}>
              <p className="title" style={{margin: "0"}}>{this.state.brand}</p>
              <hr></hr>
              <p className="text" style={{margin: "0px"}}>{this.state.total}</p>
              </Col>
              <Col sm={"auto"}>
                <svg
                  ref="legendSvg"
                  width="150px"
                  height="220px"
                ></svg>
              </Col>
            </Row>
            
            <svg
              ref="allpara"
              width="900px"
              height="300px"
            ></svg>

            <p className="title" style={{margin: "10px 0px 0px 15px"}}>Calories Per Serving</p>
            <div style={{overflowX: "scroll"}}>
            <svg
              id="allcups"
              width="2420px"
              height="320px"
            ></svg>

            <svg
              id="allcalories"
              width="2420px"
              height="280px"
            ></svg>
            </div>
            <p className="title" style={{marginLeft: "10px", marginBottom: "0"}}>Cups Per Serving</p>
        
            <p className="title">Instructions</p>
            <p className="text" style={{marginBottom: "0"}}>
              In this data visualization, both the Force Directed Graph and the Parallel 
              Coordinates Chart are interactive.
            </p>
            <p className="text" style={{margin:"10px 20px 50px 20px"}}>
              <b>For the Cereal Bowl Graph,</b> if you select one of the cereal nodes, the Parallel 
              Coordinates Chart will highlight and the cereal box image and nutritional facts underneath 
              will update with the nodes data.<br></br>
              <b>For the Parallel Coordinates Chart,</b> if you select the color square in the legend in the top 
              right, the chart with highlight all of the cereals from the coresponding cereal brand.
            </p>

            <CerealInfo
              name={this.state.name}
              image={this.state.image}
              manufact={this.state.manufact}
              cups={this.state.cups}
              calories={this.state.calories}
              fat={this.state.fat}
              sodium={this.state.sodium}
              carb={this.state.carb}
              fiber={this.state.fiber}
              sugar={this.state.sugar}
              protein={this.state.protein}
              vitamin={this.state.vitamin}
              potass={this.state.potass}
            ></CerealInfo>
            <p className="title">Dataset</p>
            <p className="text" style={{ marginBottom: "10px" }}>
              These datasets have been gathered and cleaned up by Petra Isenberg, Pierre 
              Dragicevic and Yvonne Jansen. The original source can be found here.
            </p>
            <blockquote
              style={{
                backgroundColor: "#eeeeee",
                padding: "10px",
                marginBottom: "50px",
              }}
            >
              <p className="text" style={{ marginBottom: "0px" }}>
                This data is made available under a
                <a href="https://creativecommons.org/licenses/by-sa/3.0/">
                  Creative Commons Attribution-ShareAlike 3.0 Lincense
                </a>
                <br />
                <cite>
                  Crawford, Chris.(2015). 80 Cereals[CSV file]. Kaggle. Retrieved
                  from{" "}
                  <a href="https://www.kaggle.com/crawford/80-cereals">
                    https://www.kaggle.com/crawford/80-cereals
                  </a>
                </cite>
              </p>
            </blockquote>
            <Row>
              <Col sm={3}>
                <p className="text">
                  cereal.csv <br />
                  (4.94 KB)
                </p>
                <div className="tags has-addons">
                  <span className="tag">Columns</span>
                  <span className="tag is-primary">16</span>
                </div>
                <div className="tags has-addons">
                  <span className="tag">Rows</span>
                  <span className="tag is-primary">77</span>
                </div>
              </Col>
              <Col>
                <table
                  className="table is-bordered is-narrow"
                  style={{ marginBottom: "50px" }}
                >
                  <thead>
                    <tr>
                      <th>Column</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>name</td>
                      <td>Cereal name</td>
                    </tr>
                    <tr>
                      <td>mfr</td>
                      <td>Manufacturer</td>
                    </tr>
                    <tr>
                      <td>type</td>
                      <td>Type of cereal. H (Hot) or C (Cold)</td>
                    </tr>
                    <tr>
                      <td>calories</td>
                      <td>Number of calories per seriving</td>
                    </tr>
                    <tr>
                      <td>protein</td>
                      <td>Grams of protein in cereal serving</td>
                    </tr>
                    <tr>
                      <td>fat</td>
                      <td>Grams of fat in cereal serving</td>
                    </tr>
                    <tr>
                      <td>sodium</td>
                      <td>Miligrams of sodium in cereal serving</td>
                    </tr>
                    <tr>
                      <td>fiber</td>
                      <td>Grams of fiber in cereal serving</td>
                    </tr>
                    <tr>
                      <td>carbo</td>
                      <td>Grams of carbohydrates in cereal serving</td>
                    </tr>
                    <tr>
                      <td>sugars</td>
                      <td>Grams of sugar in cereal serving</td>
                    </tr>
                    <tr>
                      <td>potass</td>
                      <td>Miligrams of potassiuum in cereal serving</td>
                    </tr>
                    <tr>
                      <td>vitamins</td>
                      <td>
                        vitamins and minerals - 0, 25, or 100, indicating the
                        typical percentage of FDA recommended
                      </td>
                    </tr>
                    <tr>
                      <td>shelf</td>
                      <td>Display shelf counting from the floor</td>
                    </tr>
                    <tr>
                      <td>weight</td>
                      <td>Weight in ounces of cereal serving</td>
                    </tr>
                    <tr>
                      <td>cups</td>
                      <td>Number of cups in cereal serving</td>
                    </tr>
                    <tr>
                      <td>rating</td>
                      <td>Rating of cereal, possibly from Consumer Reports</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
            <p className="title">Conclusions</p>
            <p className="text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
              ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
              pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
              culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p className="title">Acknowledgements & Inspirations</p>

            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm={1}>
                        <Image
                          src={jsbin}
                          style={{ height: "64px", width: "64px" }}
                        />
                      </Col>
                      <Col>
                        <p
                          className="text"
                          style={{ marginBottom: "0px", textAlign: "center" }}
                        >
                          <a href="https://jsbin.com/wecaxu/2/edit?html,js,output">
                           JS Bin - Gakebinimu
                          </a>
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card style={{ marginTop: "15px" }}>
                  <Card.Body>
                    <Row>
                      <Col sm={4}>
                        <Image
                          src="https://bulma.io/images/placeholders/96x96.png"
                          alt="Placeholder image"
                          style={{ height: "64px", width: "64px" }}
                        />
                      </Col>
                      <Col>
                        <p className="text" style={{ marginBottom: "0px" }}>
                          <a href="https://bl.ocks.org/jasondavies/1341281">
                            Parallel Coordinates
                          </a>
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm={4}>
                        <Image
                          src={codepen}
                          style={{ height: "64px", width: "64px" }}
                        />
                      </Col>
                      <Col>
                        <p className="text" style={{ marginBottom: "0px" }}>
                          <a href="https://codepen.io/chriscoyier/pen/egHEK">
                            Nutrition Facts Table in HTML and CSS
                          </a>
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <p className="title" style={{ marginTop: "50px"}}>References</p>
            <ol>
              <Row style={{ marginBottom: "150px" }}>
                <Col>
                  <li className="citation">
                    100% Bran
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.ebay.com%2Fitm%2F1996-Vintage-Post-Nabisco-100-BRAN-Cereal-Box-RARE-%2F264279819015&psig=AOvVaw3DrsSmwafY32C71qbnjEQQ&ust=1592640600068000&source=images&cd=vfe&ved=0CA0QjhxqFwoTCNDu_sK5jeoCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">100% Natural Bran</li>
                  <li className="citation">
                    All-Bran
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.target.com%2Fp%2Fall-bran-breakfast-cereal-18-6oz-kellogg-39-s%2F-%2FA-54445255&psig=AOvVaw0w8sxLMQu7JlMfOjPZTsAv&ust=1592698982220000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCOjpuZuQj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    All-Bran with Extra Fiber
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.pinterest.com%2Fpin%2F576249714801623445%2F&psig=AOvVaw2TmMdazSkG0wCnhqXuzF5f&ust=1592699186471000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCMjMrYCRj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Almond Delight
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.mrbreakfast.com%2Fcereal_detail.asp%3Fid%3D9&psig=AOvVaw0wBh75ByxGX-a36EZFevhu&ust=1592699240138000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCMDNrpeRj-oCFQAAAAAdAAAAABAX">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Apple Cinnamon Cheerios
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.pinterest.com%2Fpin%2F563864815817612314%2F&psig=AOvVaw38iufmlb2AqbGkn4sWRHUP&ust=1592699343097000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCID47MqRj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Apple Jacks
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.target.com%2Fp%2Fapple-jacks-breakfast-cereal-10-1oz-kellogg-s%2F-%2FA-13361461&psig=AOvVaw3en2MUmUtGm6rSCSG04f5m&ust=1592699400310000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCKCd8_GRj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Basic 4
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.walmart.com%2Fip%2FGeneral-Mills-Basic-4-Cereal-Family-Size-19-8-Oz%2F166082136&psig=AOvVaw0YicUWQqE5ttdB6GsA-mOU&ust=1592699501059000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCJDCopuSj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Bran Chex
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.mrbreakfast.com%2Fcereal_detail.asp%3Fid%3D444&psig=AOvVaw37YQnwYuDvdNPoyHQ10KSV&ust=1592699641714000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCLDb5NmSj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Bran Flakes
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=http%3A%2F%2Fwww.1fsschools.com%2Fproduct-detail.cfm%3FskuId%3D3136248&psig=AOvVaw0_yAUXPND-fbbkevosqHsd&ust=1592699881037000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCPjH3cqTj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Cap'n'Crunch
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.picknsave.com%2Fp%2Fcap-n-crunch-sweetened-corn-oat-cereal%2F0003000006531&psig=AOvVaw3SydwDFweJ5rCkyye676u1&ust=1592700133533000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCJiGvMGUj-oCFQAAAAAdAAAAABAb">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Cheerios
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.co.uk%2FOriginal-Cheerios-Cereal-American-Packs%2Fdp%2FB00O4EC880&psig=AOvVaw0L2GA_SFs1Hw3SF5kPZu0k&ust=1592700624457000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCKin6bGWj-oCFQAAAAAdAAAAABAg">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Cinnamon Toast Crunch
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.bjs.com%2Fproduct%2Fcinnamon-toast-crunch-cereal-495-oz%2F3000000000000149391&psig=AOvVaw0mUhwyhbMXAV6E-f7e-AHq&ust=1592700434844000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCOiShNGVj-oCFQAAAAAdAAAAABAV">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Clusters
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Ftwitter.com%2Ffo_aschatz%2Fstatus%2F674087511354945536&psig=AOvVaw2QS-x8aJE4JfuRRV_JOAPB&ust=1592700744521000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCMCz7eWWj-oCFQAAAAAdAAAAABAb">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Cocoa Puffs
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.mymilitarysavings.com%2Fmy-commissary%2Ffood%2F5261-cocoa-puffs-cereal&psig=AOvVaw0t7XhjAuKEjBuUF98Bpz7Z&ust=1592700795751000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCJjDtfyWj-oCFQAAAAAdAAAAABAV">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Corn Chex
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.med-diet.com%2Fp-2322-chex-cereal-corn.aspx&psig=AOvVaw33kxIs_K3-Bl3pdissZhej&ust=1592700870626000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCPiUy6CXj-oCFQAAAAAdAAAAABA1">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Corn Flakes
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.bjs.com%2Fproduct%2Fkelloggs-corn-flakes-43-oz%2F281127&psig=AOvVaw0zWXVpbmOrCNfyzuo7l4QG&ust=1592700935033000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCKiElcCXj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Corn Pops
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.target.com%2Fp%2Fcorn-pops-breakfast-cereal-10oz-kellogg-s%2F-%2FA-12935960&psig=AOvVaw0PlugThELGXqFypVPwYFLw&ust=1592700990154000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCPiZ852Yj-oCFQAAAAAdAAAAABAK">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Count Chocula
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.instacart.com%2Fproducts%2F1333737-count-chocula-cereal-10-4-oz&psig=AOvVaw1EoQTfCpwh9tHT_b7K2474&ust=1592701504462000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCMDEu8-Zj-oCFQAAAAAdAAAAABAI">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Cracklin' Oat Bran
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.autostraddle.com%2F50-breakfast-cereals-ranked-by-lesbianism-371865%2F&psig=AOvVaw0sLEOlwxPdYi0Ly-bPruxE&ust=1592701701690000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCMjco6yaj-oCFQAAAAAdAAAAABAP">
                        [1]
                      </a>
                    </sup>
                  </li>
                </Col>
                <Col>
                  <li className="citation">
                    Cream of Wheat
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FCream-Wheat-Enriched-Farina-Minute%2Fdp%2FB004G7PFRQ&psig=AOvVaw0HAh0W7YY79vonz3isU1rL&ust=1592701840118000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCMjgrO6aj-oCFQAAAAAdAAAAABAI">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Crispix
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fen.wikipedia.org%2Fwiki%2FCrispix&psig=AOvVaw1jJALOIP1Nou6WnhQRhxhl&ust=1592702497962000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCLDf6aidj-oCFQAAAAAdAAAAABAJ">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Crispy Wheat & Raisins
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.pinterest.com%2Fpin%2F247909154475917304%2F&psig=AOvVaw3BOXR_UHHFQ2lJEH2G0Mut&ust=1592702588486000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCLCF-tadj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Double Chex
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.mrbreakfast.com%2Fcereal_detail.asp%3Fid%3D443&psig=AOvVaw0W4xQkgD3PEvo_tLvjOWGB&ust=1592702737801000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCIiSrZ6ej-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Froot Loops
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.worldfoodswholesale.com%2Fshop%2Fcereals-breakfast%2Fcereals%2Fkellogg-s-froot-loops-417g-14-7oz-box-of-12.html&psig=AOvVaw2oIpLFjgQUU_n4dzADTnq_&ust=1592702918110000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCMDj4vWej-oCFQAAAAAdAAAAABAI">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Frosted Flakes
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fstore.jacksfoodland.com%2Fdefault%2Fkelloggs-frosted-flakes-cereal-15oz.html&psig=AOvVaw1WEUVRo8JeH03mBpdM5lbS&ust=1592703032650000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCNjm46ufj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Frosted Mini-Wheats
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.target.com%2Fp%2Foriginal-frosted-mini-wheats-breakfast-cereal-18oz-kellogg-s%2F-%2FA-13361816&psig=AOvVaw3tu3V0f_1daxbFeArRvKAl&ust=1592703096664000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCPjQ98afj-oCFQAAAAAdAAAAABAI">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Fruit & Fibre Dates; Walnuts; and Oats
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DD6HFtb4I6M8&psig=AOvVaw2NpWZJZ-Hyv2b_1M5oR06D&ust=1592703257667000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCNjn7Jegj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Fruitful Bran
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fstudio.whenhub.com%2Fschedules%2F59a583bfb198651a04678cc7%2Fworld-s-most-popular-breakfast-brand-kellogg-s&psig=AOvVaw1zNuHfc9qpKYrOv2EErKAF&ust=1592703333250000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCNjyr7igj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Fruity Pebbles
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FFruity-Pebbles-Cereal-11-Ounce-Boxes%2Fdp%2FB002LV6M9E&psig=AOvVaw1xlct-NqEQTVYpxSSRlOfy&ust=1592703390418000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCNj6hdOgj-oCFQAAAAAdAAAAABAI">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Golden Crisp
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FPost-Golden-Crisp-Cereal-14-75%2Fdp%2FB00U9W7IV8&psig=AOvVaw08O2CHaTvp4Si6TKisTeqZ&ust=1592703465496000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCODw0Pegj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Golden Grahams
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.seriouseats.com%2F2011%2F09%2Fcereal-eats-the-troubled-relationship-between.html&psig=AOvVaw02RGpqH9BgAzqiRbswL22j&ust=1592703720616000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCLC2xfKhj-oCFQAAAAAdAAAAABAV">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Grape Nut Flakes
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.meijer.com%2Fshop%2Fen%2Fcereal-breakfast%2Fcold-cereal%2Fgood-for-you-cereal%2Fpost-grape-nuts-flakes-cereal%2Fp%2F88491210910&psig=AOvVaw2pX15g-4oswxIttbTP4Q40&ust=1592703829584000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCODR6Keij-oCFQAAAAAdAAAAABAZ">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Grape-Nuts
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.hy-vee.com%2Fgrocery%2FPD3022716%2FPost-Grape-Nuts-The-Original-Cereal&psig=AOvVaw0AM8naB5o_jl8XjKXSaH9t&ust=1592703973626000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCIiDm-2ij-oCFQAAAAAdAAAAABAJ">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Great Grains Pecans
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FGreat-Grains-Crunchy-Pecans-Cereal%2Fdp%2FB0014CU7T2&psig=AOvVaw03nabFsCW9pME7mfzMkOUC&ust=1592704095321000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCJiQ3KSjj-oCFQAAAAAdAAAAABAe">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Honey Graham Ohs
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fbermda.desertcart.com%2Fproducts%2F24827777-post-honey-graham-ohs-cereal-10-5-ounce&psig=AOvVaw0dsBIU4zGJ2odfn4P2oM5d&ust=1592704191275000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCMi6m8-jj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Honey Nut Cheerios
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FHoney-Nut-Cheerios-12-25-Boxes%2Fdp%2FB010KMI284&psig=AOvVaw1HU4oAca7rx_tM-_9dHZ2W&ust=1592704291968000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCLDEroCkj-oCFQAAAAAdAAAAABAk">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Honey-comb
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.walmart.com%2Fip%2FPost-Honey-Comb-Corn-Oat-Breakfast-Cereal-16-Oz%2F16785102&psig=AOvVaw0G8uWSP3QHsozj7zryOve8&ust=1592704471115000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCODXxNakj-oCFQAAAAAdAAAAABAP">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Just Right Crunchy Nuggets
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.mrbreakfast.com%2Fcereal_detail.asp%3Fid%3D210&psig=AOvVaw3uN-x-UH3o1Dryq_Pz0UFm&ust=1592704540553000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCOjBnPikj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Just Right Fruit & Nut
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Faruba.desertcart.com%2Fproducts%2F50401742-kelloggs-just-right-cereal-500-g&psig=AOvVaw2RLSNbr21KIGUje0C6bUQy&ust=1592704597072000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCMjj3JOlj-oCFQAAAAAdAAAAABAa">
                        [1]
                      </a>
                    </sup>
                  </li>
                </Col>
                <Col>
                  <li className="citation">
                    Kix
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FKix-18-Ounce-Pack-5%2Fdp%2FB005EKI468&psig=AOvVaw0-49E7GQRgcpoEDjggW1Kp&ust=1592704718313000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCJDvyc2lj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Life
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.meijer.com%2Fshop%2Fen%2Fspecialty-diet%2Fdietitian-picks%2Frheumatoid-arthritis%2Fquaker-life-cereal-13-oz%2Fp%2F3000006354&psig=AOvVaw3U_jjDmLF4KlL9Qtz7xCpk&ust=1592704764441000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCMi_--Clj-oCFQAAAAAdAAAAABAU">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Lucky Charms
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.pinterest.com%2Fpin%2F209276713903865684%2F&psig=AOvVaw1HOAGqSMBWeyCaDh9looOm&ust=1592704905223000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCIji4qOmj-oCFQAAAAAdAAAAABAo">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Maypo
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FMaypo-Instant-Maple-Oatmeal-14-Ounce%2Fdp%2FB000H223O2&psig=AOvVaw1weqC9VVvppaaHlgMMJIXP&ust=1592705042954000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCIjqveamj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Muesli Raisins; Dates; & Almonds
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.ebay.com%2Fitm%2F1988-RALSTON-MUESLI-NEW-CEREAL-BOX-FREE-SHIPPING-%2F153869823102&psig=AOvVaw3H3py1WgHyRzqNHRMpj5H6&ust=1592705223737000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCODqwb6nj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Muesli Raisins; Peaches; & Pecans
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.ebay.com%2Fitm%2F1988-RALSTON-MUESLI-NEW-CEREAL-BOX-FREE-SHIPPING-%2F153869823102&psig=AOvVaw3H3py1WgHyRzqNHRMpj5H6&ust=1592705223737000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCODqwb6nj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Mueslix Crispy Blend
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FKelloggs-Mueslix-15-3-Ounce-Boxes-Pack%2Fdp%2FB00O5NASYG&psig=AOvVaw0mKcPmKUS-gkTJcZgIH2aA&ust=1592709490569000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCLDytK-3j-oCFQAAAAAdAAAAABAJ">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Multi-Grain Cheerios
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freshdirect.com%2Fpdp.jsp%3FproductId%3Dgro_cheerios_multigrn_12oz%26catId%3Dgro_cerea_adult&psig=AOvVaw3zxefw-jDJiVSvjsXUmuEx&ust=1592709543810000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCLCDi8m3j-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Nut&Honey Crunch
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.mrbreakfast.com%2Fcereal_detail.asp%3Fid%3D717&psig=AOvVaw3fdQd9npJd_V95q1krGM7P&ust=1592709583120000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCJDV2dq3j-oCFQAAAAAdAAAAABAH">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Nultri-Grain Almond-Raisin
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fbadtothebowl.com%2Fcrockpot-apple-cinnamon-creamy-buckwheat-porridge-vegan-gluten-free%2F&psig=AOvVaw3Jj2TCX81v25v3TNO27D4K&ust=1592709665998000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCJC_iYW4j-oCFQAAAAAdAAAAABAH">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Nultri-grain Wheat
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.mrbreakfast.com%2Fcereal_detail.asp%3Fid%3D723&psig=AOvVaw3JJVI4ixbyZSUBnnlwSChE&ust=1592709755239000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCKjpp624j-oCFQAAAAAdAAAAABAM">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Oatmeal Raisin Crisp
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.walmart.com%2Fip%2FOatmeal-Crisp-with-Hearty-Raisins-Cereal-18-oz%2F10311416&psig=AOvVaw2Xc3NcdnynxtXQs7EicYkS&ust=1592709858917000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCOjj-N-4j-oCFQAAAAAdAAAAABAQ">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Post Nat. Raisin Bran
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FPost-Raisin-Bran-Whole-Cereal%2Fdp%2FB01M4NM40Y&psig=AOvVaw16UaUcLRL2jonOsLi7bIv4&ust=1592709976541000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCPDiipa5j-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Product 19
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.seriouseats.com%2F2013%2F08%2Fcereal-eats-whats-the-deal-with-product-19.html&psig=AOvVaw3xV59SFgtF7Fdi28I70MYI&ust=1592710016085000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCLDCx6i5j-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Puffed Rice
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.walmart.com%2Fip%2FQuaker-Puffed-Rice-Cereal-6-3-Oz%2F28924343&psig=AOvVaw2VgcSjgvGvLsL1jM7YLhsk&ust=1592710071712000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCIiC9MK5j-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Puffed Wheat
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.instacart.com%2Fproducts%2F119411-quaker-puffed-wheat-regular-cereal-5-2-oz&psig=AOvVaw1qsITiarSxV4rggCOuh2t0&ust=1592710112625000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCOj_19a5j-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Quaker Oat Squares
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FQuaker-Oatmeal-Squares-Cereal-14-5%2Fdp%2FB0014CUCU6&psig=AOvVaw3wA7Jvv5f-wf4us9fQ2KKa&ust=1592710156286000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCND3x-u5j-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Quaker Oatmeal
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.target.com%2Fp%2Fquaker-instant-oatmeal-lower-sugar-variety-pack-10ct%2F-%2FA-13331301&psig=AOvVaw2mOVJXka38Kpl1Rk8-pbj6&ust=1592710189512000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCID0jf25j-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Raisin Bran
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.roundeyesupply.com%2FKelloggs-Raisin-Bran-Cereal-16-6-Oz-p%2Fde689934.htm&psig=AOvVaw2lrKhqQAsnm8x3AMKW6pYp&ust=1592710243868000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCNDNyJm6j-oCFQAAAAAdAAAAABAJ">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Raisin Nut Bran
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FGeneral-Mills-Raisin-Bran-Cereal%2Fdp%2FB0043IHVLQ&psig=AOvVaw1lTq08gUxQlx_nqcoabP6Y&ust=1592710360485000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCIiGmc-6j-oCFQAAAAAdAAAAABAa">
                        [1]
                      </a>
                    </sup>
                  </li>
                </Col>
                <Col>
                  <li className="citation">
                    Raisin Squares
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.pinterest.com%2Fpin%2F114490015500816822%2F&psig=AOvVaw0Mc2cGJAcnrODg39l-NpIk&ust=1592721268605000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCLjDu8vjj-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Rice Chex
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FGeneral-Mills-Rice-Toasted-Gluten%2Fdp%2FB071X5DZVX&psig=AOvVaw2dnhnwqjtDmovfpkhTk4hX&ust=1592710459921000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCLjc5P66j-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Rice Krispies
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.target.com%2Fp%2Frice-krispies-breakfast-cereal-12oz-kellogg-s%2F-%2FA-13361813&psig=AOvVaw0QzbkoGTbgWT8KL0SPvVnl&ust=1592710558082000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCOC7qau7j-oCFQAAAAAdAAAAABAD"></a>
                    </sup>
                  </li>
                  <li className="citation">
                    Shredded Wheat
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fpicclick.co.uk%2FOriginal-1983-Nabisco-Shredded-Wheat-Cereal-Box-263534808242.html&psig=AOvVaw1Yu1rX2AiUmxW5WKXSYBTM&ust=1592710621182000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCMCG9My7j-oCFQAAAAAdAAAAABAf">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Shredded Wheat 'n' Bran
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.walmart.com%2Fip%2FPost-Shredded-Wheat-Breakfast-Cereal-Wheat-Bran-18-Oz%2F15716905&psig=AOvVaw1X-vrppuee0L1g3WztbOdN&ust=1592710774201000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCNicj5S8j-oCFQAAAAAdAAAAABAU">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Shredded Wheat spoon size
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.mrbreakfast.com%2Fcereal_ucp_slideshow.asp%3Fid%3D341%26picid%3D1219&psig=AOvVaw28suj_jalFDTZkSwL9iLfj&ust=1592710875292000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCPjdysK8j-oCFQAAAAAdAAAAABAM">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Smacks
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.food4less.com%2Fp%2Fkellogg-s-honey-smacks-cereal%2F0003800039103&psig=AOvVaw3PsfLUjRFq_zBBRIDBOZVz&ust=1592710919957000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCNjyzde8j-oCFQAAAAAdAAAAABAI">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Special K
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.fishersfoods.com%2Fshop%2Fgrocery%2Fcereal_and_breakfast_foods%2Fcereal%2Fspecial_k_cereal_toasted_rice_original%2Fp%2F26252&psig=AOvVaw2wrk55debUdJRy-R9giNrN&ust=1592710954831000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCKDqg-68j-oCFQAAAAAdAAAAABAJ">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Strawberry Fruit Wheats
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.quora.com%2FThere-was-a-cereal-in-the-80s-like-Frosted-Mini-Wheats-except-with-fruit-like-strawberries-inside-What-was-that-cereal-and-can-I-still-get-it&psig=AOvVaw3gKqhy6QH9r7iRCgJXIXNt&ust=1592711011709000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCOCExYa9j-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Total Corn Flakes
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.mrbreakfast.com%2Fcereal_ucp_slideshow.asp%3Fid%3D376%26picid%3D1816&psig=AOvVaw0iAI3FqVknY6f6AWlFNE10&ust=1592711191738000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCLiUyty9j-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Total Raisin Bran
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.ewg.org%2Ffoodscores%2Fproducts%2F016000275133-GeneralMillsTotalRaisinBran%2F&psig=AOvVaw2GotbGBx5HA2lh2e8U_w3I&ust=1592711111216000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCLCvsrO9j-oCFQAAAAAdAAAAABAV">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Total Whole Grain
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FTotal-Whole-Grain-Breakfast-Cereal%2Fdp%2FB0010VMEP2&psig=AOvVaw0L3aZcMrmfxnhyCrt6Ebgv&ust=1592711069035000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCNiyrqC9j-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Triples
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fpicclick.com%2F1991-Vintage-General-Mills-TRIPLES-Crisp-Corn-Rice-Wheat-Cereal-263981441916.html&psig=AOvVaw2hzmEI3PEyw4ENt81SfPL4&ust=1592711259160000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCJjNqfm9j-oCFQAAAAAdAAAAABAf">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Trix
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FTrix-Cereal-10-7-ounce-Box-Pack%2Fdp%2FB009563BY2&psig=AOvVaw1ORlAUWMYKk_MPtbv5E8HK&ust=1592711335772000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCOCtkJ6-j-oCFQAAAAAdAAAAABAt">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Wheat Chex
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.amazon.com%2FGeneral-Mills-Chex-Cereal-Wheat%2Fdp%2FB0043IHUQC&psig=AOvVaw3tptnzyxCGzs125f7P9JAo&ust=1592711411911000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCLDMnMK-j-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Wheaties
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fmedium.com%2F%40michaelmargulis%2Fthe-wheaties-principle-how-players-market-themselves-and-their-likeness-57bec9506ae2&psig=AOvVaw1lI7kDqlA9c7V5lmI1OF4u&ust=1592711498476000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCJDk3-u-j-oCFQAAAAAdAAAAABAZ">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Wheaties Honey Gold
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.mrbreakfast.com%2Fcereal_detail.asp%3Fid%3D1196&psig=AOvVaw1V7rZQpfbbt1JUBmA1X5qg&ust=1592711562960000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCKiRm4q_j-oCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Measuring Cup Clipart
                    <sup>
                      <a href="https://www.google.com/url?sa=i&url=http%3A%2F%2Fclipart-library.com%2Fpicture-of-a-measuring-cup.html&psig=AOvVaw3l098iTCX6qr1Lzgi1cwIo&ust=1592798382168000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCMjMiMiCkuoCFQAAAAAdAAAAABAD">
                        [1]
                      </a>
                    </sup>
                  </li>
                  <li className="citation">
                    Shelves
                    <sup>
                      <a href="https://svgsilh.com/image/575818.html">[1]</a>
                    </sup>
                  </li>
                </Col>
              </Row>
            </ol>
          </Col>
          </Row>
      </div>
    )
  }
}

export default Cereal
